const renderAmazon = () => {
  const zones = document.querySelectorAll("[data-zone-amazon_adsystem]")
  zones.forEach(zone => {
    const code = zone.getAttribute("data-zone-amazon_adsystem")

    const div = document.createElement("div")
    div.id = `amzn-assoc-ad-${code}`

    const script = document.createElement("script")
    script.setAttribute("async", "async")
    script.src =
      "//z-na.amazon-adsystem.com/widgets/onejs" +
      `?MarketPlace=US&adInstanceId=${code}`

    zone.appendChild(div)
    zone.appendChild(script)
  })
}

export { renderAmazon }
