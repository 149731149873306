const renderCarbon = () => {
  const zones = document.querySelectorAll("[data-zone-carbon]")
  zones.forEach(zone => {
    const code = zone.dataset.zoneCarbon

    const script = document.createElement("script")
    script.setAttribute("async", "async")
    script.src =
      `//cdn.carbonads.com/carbon.js?serve=${code}&placement=carbonadsnet`
    script.id = "_carbonads_js"
    zone.appendChild(script)
  })
}

export { renderCarbon }
