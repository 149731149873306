document.addEventListener("turbo:load", () => {
  document.links.forEach((link) => {
    if (!link.href) return
    if (link.rel != "") return
    if (link.target != "") return

    if (
      link.hostname == window.location.hostname &&
      !link.href.match(/\.pdf\z/)
    ) return

    link.rel = "noopener"
    link.target = "_blank"
  })
})
