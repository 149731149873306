import { adDetector } from "helpers/zones/ad_detector"
import {
  renderAdnimation,
  renderAdnimationVideo
} from "helpers/zones/adnimation"
import { renderOutbrain } from "helpers/zones/outbrain"
import { renderAmazon } from "helpers/zones/amazon"
import { renderCarbon } from "helpers/zones/carbon"
import { hideHidden, showFallback, clear } from "helpers/zones/zones"

const renderAds = () => {
  renderAdnimation()
  renderAdnimationVideo()
  renderOutbrain()
  renderAmazon()
}

const renderNoAds = () => {
  renderCarbon()
  hideHidden()
  showFallback()
}

document.addEventListener("turbo:load", () => {
  adDetector(renderAds, renderNoAds)
})

document.addEventListener("turbo:before-cache", clear)
