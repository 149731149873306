const renderOutbrain = () => {
  const zones = document.querySelectorAll("[data-zone-outbrain]")
  if (zones.length == 0) return

  zones.forEach(zone => {
    const div = document.createElement("div")
    div.className = "OUTBRAIN"
    div.setAttribute("data-widget-id", zone.dataset.zoneOutbrain)
    div.setAttribute("data-ob-contentUrl", "%%PATTERN:url%%")
    div.setAttribute("data-ob-installation-key", "ADNIMKAJDGAG4GAO6AGG6H5KP")
    zone.appendChild(div)
  })

  if (window.OBR) {
    window.OBR.extern.reloadWidget()
  } else {
    const script = document.createElement("script")
    script.setAttribute("async", "async")
    script.src = "//widgets.outbrain.com/outbrain.js"
    document.head.appendChild(script)
  }
}

export { renderOutbrain }
