import { addBubbleListener } from "../helpers/add_bubble_listener"

// Track events in Google Analytics
//
// Track clicks as events
//
// Example:
//     <button data-anal-click-category="slider"
//             data-anal-click-label="next" />
addBubbleListener("click", "[data-anal-click-category]", (e) => {
  if (!window.ga) return

  const data = e.target.dataset
  const category = data.analClickCategory
  const action = data.analClickAction || "click"
  const label = data.analClickLabel
  window.ga("send", "event", category, action, label)
})

// Track text selection as events only if the selected text matches the label.
//
// Example:
//     <div data-anal-select-category="copy"
//          data-anal-select-label="FOO">
//       Please copy this: "FOO"
//     </div>
const getSelection = () => {
  if (window.getSelection) {
    return window.getSelection().toString()
  } else if (document.selection) {
    return document.selection.createRange().text
  }
}

addBubbleListener("mouseup", "[data-anal-select-category]", (e) => {
  if (!window.ga) return

  const data = e.target.dataset
  const label = data.analSelectLabel
  if (getSelection() != label) return

  const category = data.analSelectCategory
  const action = data.analSelectAction || "select"
  window.ga("send", "event", category, action, label)
})

// Track JavaScript errors
// http://davidwalsh.name/track-errors-google-analytics
window.addEventListener("error", (e) => {
  if (!window.ga) return

  const category = "JavaScript Error"
  const label = e.message
  const text = `${e.filename}:${e.lineno}`
  window.ga("send", "event", category, label, text, { nonInteraction: 1 })
})
