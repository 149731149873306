import ClipboardJS from "clipboard"

document.addEventListener("turbo:load", () => {
  const clipboard = new ClipboardJS("[data-clipboard-target]")

  clipboard.on("success", (e) => {
    e.trigger.innerText = e.trigger.dataset.clipboardSuccessText
  })

  clipboard.on("error", (e) => {
    e.trigger.innerText = e.trigger.dataset.clipboardErrorText
  })
})
