const hideHidden = () => {
  document.querySelectorAll("[data-zone-hidden]").forEach(zone => {
    zone.style.display = "none"
  })
}

const showFallback = () => {
  document.querySelectorAll("[data-zone-fallback]").forEach(zone => {
    if (zone.dataset.zoneCarbon) return
    zone.innerHTML = zone.dataset.fallback
  })
}

const clear = () => {
  document.querySelectorAll("[data-zone]").forEach(zone => {
    zone.innerHTML = ""
  })
}

export { hideHidden, showFallback, clear }
