const renderAdnimation = () => {
  const zones = document.querySelectorAll("[data-zone-adnimation]")
  if (zones.length == 0) return

  if (window.adn) window.adn.cmd.push(window.adn.destroy)

  zones.forEach((zone) => {
    const div = document.createElement("div")
    div.setAttribute("data-aaad", "true")
    div.setAttribute("data-aa-adunit", zone.dataset.zoneAdnimation)
    zone.appendChild(div)
  })

  if (window.adn) {
    window.adn.cmd.push(window.adn.processAdsOnPage)
  } else {
    insertAdnimation()
    insertConfiant()
  }
}

const insertAdnimation = () => {
  window.adn = { "cmd": [] }

  const script = document.createElement("script")
  script.setAttribute("async", "async")
  script.src = "https://adncdnend.azureedge.net/adn-hb/adn.c3d.js"
  document.head.appendChild(script)
}

const insertConfiant = () => {
  const propertyId = "d2ehZtyVAa1kXxOtMxFp7XciXcU"
  window.confiant = window.confiant || {}
  window.confiant[propertyId] =
    window.confiant[propertyId] || { clientSettings: {} }
  const clientSettings =
    window.confiant[propertyId].clientSettings ||
    (window.confiant[propertyId].clientSettings = {})
  clientSettings.prebidExcludeBidders = ["criteo", "sharethrough"]

  const script = document.createElement("script")
  script.setAttribute("async", "async")
  script.src =
    "https://confiant-integrations.global.ssl.fastly.net/" + propertyId +
    "/gpt_and_prebid/config.js"
  document.head.appendChild(script)
}

const renderAdnimationVideo = () => {
  const zones = document.querySelectorAll("[data-zone-adnimation_video]")
  if (zones.length == 0) return

  const video = document.getElementById("adnimationjs")
  if (video) video.remove()

  zones.forEach(zone => {
    const code = zone.getAttribute("data-zone-adnimation_video")

    const div = document.createElement("div")
    div.id = code
    zone.appendChild(div)

    const script = document.createElement("script")
    script.setAttribute("async", "async")
    script.setAttribute("data-info", code)
    script.id = "adnimationjs"
    script.src = "https://adncdnend.azureedge.net/adn-video/Cults3d_Player.js"
    document.head.appendChild(script)
  })
}

export { renderAdnimation, renderAdnimationVideo }
